import React from "react";

export const Demo = (props) => {
  return (
    <div id="demo" className="text-center">
      <div className="col-md-8 col-md-offset-2 section-title">
        <h2>Démonstration</h2>
        <div>
          <iframe
            width="100%"
            height="80%"
            // https://www.youtube.com/embed/v=QcUDzqD0Ick&ab_channel=SmartPost
            src={`https://www.youtube.com/embed/QcUDzqD0Ick`}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Embedded youtube"
          />
        </div>
      </div>
    </div>
  );
};
